<template>
  <div class="white-block-content content-text">
    <SsrHead :meta="meta" />

    <h1 class="mb-5">{{ $t("delete_account_page.title") }}</h1>

    <h2>{{ $t("delete_account_page.in_app") }}</h2>

    <p>
      <strong>{{ $t("delete_account_page.important") }}: </strong>

      {{ $t("delete_account_page.cannot_register_again") }}
    </p>

    <p>{{ $t("delete_account_page.to_delete_account") }}:</p>

    <div>
      <ol>
        <li>{{ $t("delete_account_page.app_1") }}</li>
        <li>{{ $t("delete_account_page.app_2") }}</li>
        <li>{{ $t("delete_account_page.app_3") }}</li>
      </ol>
    </div>

    <h2>{{ $t("delete_account_page.on_website") }}</h2>

    <p>
      <strong>{{ $t("delete_account_page.important") }}: </strong>

      {{ $t("delete_account_page.cannot_register_again") }}
    </p>

    <p>{{ $t("delete_account_page.to_delete_account") }}:</p>

    <div>
      <ol class="list-numbered">
        <li>
          {{ $t("delete_account_page.visit") }}
          <RouterLink to="/profile/settings" class="underline">
            /profile/settings
          </RouterLink>
        </li>

        <li>
          {{ $t("delete_account_page.click_delete_account") }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

import SsrHead from "../components/SsrHead";
import { RouterLink } from "vue-router";

export default {
  name: "NotFound",
  components: {
    SsrHead,
    RouterLink,
  },
  setup() {
    const { t } = useI18n();

    const meta = {
      title: t("delete_account_page.in_app"),
      meta: [{ name: "robots", content: "noindex, nofollow" }],
    };
    useMeta(meta);

    return {
      t,
      meta,
    };
  },
};
</script>

<style scoped>
.underline {
  text-decoration: underline;
}

.content-text {
  margin-right: auto;
  margin-left: auto;
  max-width: 70ch;
  text-align: start;
}

.mb-5 {
  margin-bottom: 5rem;
}

.list-numbered {
  list-style-type: decimal;

  li {
    display: list-item;
  }
}
</style>
